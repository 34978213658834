import styled from "styled-components";
import { Rem, Colors } from "../../commons/Theme";

export const StyledPacBee = styled.div`
  background-color: ${Colors.black}; 
`;

export const StyledLogo = styled.div`
  position: absolute;
  top: ${Rem(10)};
  left: ${Rem(20)};
`;

export const StyledLogoImage = styled.img`
 width: ${Rem(60)};
 z-index: 9;
`;
