import React from "react";
import Meta from "../components/Meta/Meta";
import PacBee from "../components/PacBee/PacBee";

const PacBeePage = () => {
  return (
    <>
      <Meta />
      <PacBee />
    </>
  );
};

export default PacBeePage;
